import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import ModularBlocks from '../components/ModularBlocks';

const TeamSingleTemplate = ({
  data: {
    datoCmsTeam: {
      seoMetaTags,
      title,
      role,
      bannerText,
      bannerImage,
      modularBlocks,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        role={role}
        text={bannerText}
        image={bannerImage}
      />
      <Breadcrumbs
        breadcrumb={{ slug: 'our-people', text: 'Our People' }}
        currentPageTitle={title}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const TeamSingleTemplateQuery = graphql`
  query TeamSingleTemplateQuery($id: String!) {
    datoCmsTeam(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      role
      bannerText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
      }
    }
  }
`;

export default TeamSingleTemplate;
